<template>
    <div class="table-box">
        <div class="down-drop">
            <div class="btn btn-default left">
                <span title="Generate PDF" @click="generatePdf" class="material-icons pointer">picture_as_pdf</span>
            </div>
            <b-taginput class="dropzone" 
                rounded 
                :tabstop="false"
                :before-adding="beforeAdding"
                ellipsis
                readonly="true"
                type="is-success"
                v-model="groupTags"
                icon="label"
                placeholder="Drop Zone"
                maxtags="1">
            </b-taginput>
            <downloadexcel
                class="btn btn-default left"
                :data="filteredExports"
                type="xls"
                worksheet="Car Show Scores"
                name="ScoreList.xls">
                <span class="material-icons pointer" title="Download Score List">download</span>
            </downloadexcel>
        </div>
        <div>
            <vue-good-table
                :columns="columns"
                :rows="groupedScores"
                compactMode
                :fixed-header="false"
                max-height="600px"
                theme="polar-bear"
                styleClass="vgt-table condensed striped bordered"
                :sort-options="sortOptions"
                :group-options="groupOptions">
                <template slot="table-column" slot-scope="props">
                    <span v-if="addToGrouping(props.column.label)" title="Drag to drop zone for grouping.">
                        <div id="draggable" draggable="true" class="dropzone" ondragstart="event.dataTransfer.setData('text/plain',null)">
                            {{ props.column.label }}
                        </div>
                    </span>
                    <span v-else>
                        {{props.column.label}}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'after'">
                        <router-link :to="{name: 'edit', params: { id: props.row.docId }}">
                            <span class="material-icons" title="Edit this Score">edit</span>
                        </router-link>
                        <span class="material-icons pointer" @click.prevent="deleteScore(props.row.docId)" title="Delete this Score">delete_forever</span>
                    </span>
                    <span v-else>
                        {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { db } from '@/services/firebase/index.js';
    import 'vue-good-table/dist/vue-good-table.css'
    import jsPDF from "jspdf";
    import 'jspdf-autotable'
    import { VueGoodTable } from 'vue-good-table';
    import downloadexcel from "vue-json-excel";
    export default {
        name: 'scoreList',
        data() {
            return {
                scores: [],
                columns: [],
                groupTags: [],
                dragged: null,
                sortOptions: {
                    enabled: true,
                    initialSortBy: {field: 'total', type: 'desc'}
                },
                groupOptions: {
                    default() {
                    return {
                        enabled: false,
                        collapsable: false,
                        rowKey: null
                    };
                }
                },
                groupItem: {
                    mode: 'span',
                    label: '',
                    html: true,
                    children: []
                },
                base64Img: null
            }
        },
        components: {
            downloadexcel,
            VueGoodTable
        },
        created() {
            let vm = this;
            this.getScoreData();
        
            /* events fired on the draggable target */
            document.addEventListener("dragstart", function( event ) {
                this.dragged = event.target;
                event.target.style.opacity = .5;
            }, false);

            document.addEventListener("dragstart", function(event) {
                // store a ref. on the dragged elem
                this.dragged = event.target;
                // make it half transparent
                event.target.style.opacity = .5;
            }, false);

            document.addEventListener("dragend", function(event) {
                // reset the transparency
                event.target.style.opacity = "";
            }, false);

            /* events fired on the drop targets */
            document.addEventListener("dragover", function(event) {
                // prevent default to allow drop
                event.preventDefault();
            }, false);

            document.addEventListener("dragenter", function(event) {
                // highlight potential drop target when the draggable element enters it
                if (event.target.className == "input") {
                    event.target.style.background = "lightgrey";
                }

            }, false);

            document.addEventListener("dragleave", function(event) {
                // reset background of potential drop target when the draggable element leaves it
                if (event.target.className == "input") {
                    event.target.style.background = "";
                }
            }, false);
            
            document.addEventListener("drop", function( event ) {
                    event.preventDefault()
                    if (event.target.className == "input") {
                        event.target.style.background = "";
                        vm.groupTags.push(this.dragged.firstChild.data.trim());
                        vm.detailKey = this.dragged.firstChild.data.trim();
                    }
                }, false);
        
            this.columns = [
                {
                    label: 'Id',
                    field: 'docId',
                    hidden: true,
                    thClass: 'text-center'
                },
                {
                    label: 'Car',
                    field: 'carNumber',
                    thClass: 'text-center',
                    type: 'number',
                    width: '90px'
                },
                {
                    label: 'Year',
                    field: 'year',
                    type: 'number',
                    tdClass: 'text-center',
                    thClass: 'text-center',
                    width: '90px'
                },
                {
                    label: 'Category',
                    field: 'category',
                    thClass: 'text-center',
                    sortable: false,
                    //width: '20px'
                },
                {
                    label: 'Make',
                    field: 'make',
                    thClass: 'text-center',
                    sortable: false,
                    //width: '20px'
                },
                {
                    label: 'Model',
                    field: 'model',
                    thClass: 'text-center',
                    sortable: false,
                    //width: '20px'
                },
                {
                    label: 'Exterior',
                    field: 'exteriorBody',
                    type: 'number',
                    tdClass: 'text-center',
                    thClass: 'text-center',
                    width: '90px'
                },
                {
                    label: 'Interior',
                    field: 'interior',
                    type: 'number',
                    tdClass: 'text-center',
                    thClass: 'text-center',
                    width: '90px'
                },
                {
                    label: 'Engine',
                    field: 'underHood',
                    type: 'number',
                    tdClass: 'text-center',
                    thClass: 'text-center',
                    width: '90px'
                },
                {
                    label: 'Wheels',
                    field: 'wheels',
                    type: 'number',
                    tdClass: 'text-center',
                    thClass: 'text-center',
                    width: '90px'
                },
                {
                    label: 'Trunk',
                    field: 'trunk',
                    type: 'number',
                    tdClass: 'text-center',
                    thClass: 'text-center',
                    width: '90px'
                },
                {
                    label: 'Total',
                    field: 'total',
                    type: 'number',
                    tdClass: 'text-center',
                    thClass: 'text-center',
                    width: '90px'
                }
            ]
        },
        computed: {
            groupedScores() {
                if(this.groupOptions.enabled) {
                    if (this.groupTags[0] == 'Make') {
                        return _.map(this.makes, mak => (
                            {
                                mode: 'span',
                                label: mak.make,
                                html: true,
                                children: _.filter(this.scores, function(o) { return o.make == mak.make })
                            }
                        ));
                    }
                    if (this.groupTags[0] == 'Category') {
                        return _.map(this.catagories, cat => (
                            {
                                mode: 'span',
                                label: cat.category,
                                html: true,
                                children: _.filter(this.scores, function(o) { return o.category == cat.category })
                            }
                        ));
                    }
                    if (this.groupTags[0] == 'Model') {
                        return _.map(this.model, mod => (
                            {
                                mode: 'span',
                                label: mod.model,
                                html: true,
                                children: _.filter(this.scores, function(o) { return o.model == mod.model })
                            }
                        ));
                    }
                }
                return this.scores;
            },
            catagories() {
                return _.uniqBy(this.scores, function (e) {
                    return e.category;
                });
            },
            makes() {
                return _.uniqBy(this.scores, function (e) {
                    return e.make;
                });
            },
            model() {
                return _.uniqBy(this.scores, function (e) {
                    return e.model;
                });
            },
            filteredExports() {
                var exports = _.clone(this.scores);
                exports.forEach(e => {delete e.id});
                var sortDirection = this.ascending? 'asc': 'desc';
                return _.orderBy(exports, this.sortColumn, sortDirection);
            },
            scoresDbName() {
                return this.$store.getters.getScoresDatabaseName;
            },
            isJudge() {
                return this.$store.getters.getIsJudge;
            },
            userInfo() {
                return this.$store.getters.getUserCollection;
            }
        },
        methods: {
            getScoreData() {
                let vm = this;
                db.collection(this.scoresDbName)
                    .onSnapshot((snapshotChange) => {
                        vm.scores = [];
                        snapshotChange.forEach((doc) => {
                            vm.scores.push({
                                docId: doc.id,
                                carNumber: parseInt(doc.data().carNumber),
                                year: parseInt(doc.data().year),
                                category: doc.data().category,
                                make: doc.data().make,
                                model: doc.data().model,
                                exteriorBody: parseInt(doc.data().exteriorBody),
                                interior: parseInt(doc.data().interior),
                                underHood: parseInt(doc.data().underhood),
                                wheels: parseInt(doc.data().wheels),
                                trunk: parseInt(doc.data().trunk),
                                total: parseInt(doc.data().total)
                            })
                        });
                });
            },
            deleteScore(id){
              if (window.confirm("Do you really want to delete?")) {
                db.collection(this.scoresDbName).doc(id).delete().then(() => {
                    console.log("Score deleted!");
                })
                .catch((error) => {
                    console.error(error);
                })
              }
            },
            beforeAdding(tag) {
                return tag.length > 0;
            },
            addToGrouping(columnName) {
                switch (columnName) {
                    case 'Category': return true;
                    case 'Make': return true;
                    case 'Model': return true;
                    default: return false;
                }
            },
            generatePdf() {
                var doc = null;
                var body = [];
                var columns = this.columns.map(x => ({'dataKey': x.field, 'header': x.label}));
                columns.splice(columns.findIndex(v => v.header === "Id"), 1);
                if(this.groupOptions.enabled) {
                    columns.splice(columns.findIndex(v => v.header === "Category"), 1);
                    body = this.groupedScores.sort(function (a, b) {  return a.label - b.label; });
                    doc = this.drawGroupedTable(columns, body);
                } else {
                    body = this.groupedScores.sort(function (a, b) {  return b.total - a.total; });
                    doc = this.drawTable(columns, body);
                }
                columns = this.columns.map(x => ({'dataKey': x.field, 'header': x.label}));
                columns.splice(columns.findIndex(v => v.header === "Id"), 1);
                this.addBestEngine(doc, columns);
                this.addBestWheels(doc, columns);
                this.addBestInterior(doc, columns);
                this.addBestPaint(doc, columns);
                this.addBestInShow(doc, columns);
                doc.save('CarShowScores.pdf');
            },
            drawTable(columns, body) {
                var vm = this;
                var doc = new jsPDF();
                var totalPagesExp = '{total_pages_count_string}';
                doc.setFontSize(11)
                doc.setTextColor(100)
                doc.autoTable({ columns: columns, 
                                body: body,
                                didDrawPage: function (body) {
                                    //---------
                                    //---Header
                                    //---------
                                    doc.setFontSize(20);
                                    doc.setTextColor(40);
                                    if (vm.base64Img) {
                                        doc.addImage(vm.base64Img, 'JPEG', body.settings.margin.left, 15, 10, 10);
                                    }
                                    doc.text('Car Show Scores', body.settings.margin.left + 15, 22);
                                    //---------
                                    //---Footer
                                    //---------
                                    var str = 'Page ' + doc.internal.getNumberOfPages();
                                    if (typeof doc.putTotalPages === 'function') {
                                        str = str + ' of ' + totalPagesExp
                                    }
                                    doc.setFontSize(10)
                                    var pageSize = doc.internal.pageSize;
                                    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                                    doc.text(str, body.settings.margin.right, pageHeight - 10)
                                },
                                styles: { pageBreak: 'avoid', overflow: 'hidden' },
                                margin: { top: 25, left: 15, right: 15, bottom: 15 },
                                theme: "striped",
                                showHead: "everyPage"
  
                });
                //---------------------------------------------------------
                //---Total page number plugin only available in jspdf v1.0+
                //---------------------------------------------------------
                if (typeof doc.putTotalPages === 'function') {
                    doc.putTotalPages(totalPagesExp);
                }
                return doc;
            },
            drawGroupedTable(columns, body) {
                var vm = this;
                var doc = new jsPDF();
                var totalPagesExp = '{total_pages_count_string}';
                doc.setFontSize(11)
                doc.setTextColor(100)
                body.forEach((category) => {
                    var finalY = 25;
                    if (doc.lastAutoTable) {
                        finalY = doc.lastAutoTable.finalY + 10;
                    }   
                    doc.setTextColor(255, 26, 0);
                    doc.text(category.label, 10, finalY);
                    doc.setTextColor(255, 0, 0);
                    var body = category.children.sort(function (a, b) {  return b.total - a.total; }).slice(0,3);
                    doc.autoTable({ startY: finalY+3, 
                                    columns, 
                                    body,
                                    didDrawPage: function (body) {
                                        //---------
                                        //---Header
                                        //---------
                                        doc.setFontSize(20);
                                        doc.setTextColor(40);
                                        if (vm.base64Img) {
                                            doc.addImage(vm.base64Img, 'JPEG', body.settings.margin.left, 15, 10, 10);
                                        }
                                        doc.text('Car Show Scores', body.settings.margin.left + 15, 22);
                                        //---------
                                        //---Footer
                                        //---------
                                        var str = 'Page ' + doc.internal.getNumberOfPages();
                                        if (typeof doc.putTotalPages === 'function') {
                                            str = str + ' of ' + totalPagesExp
                                        }
                                        doc.setFontSize(10)
                                        var pageSize = doc.internal.pageSize;
                                        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                                        doc.text(str, body.settings.margin.right, pageHeight - 10)
                                    },
                                    styles: { pageBreak: 'avoid', overflow: 'hidden' },
                                    margin: { top: 25, left: 15, right: 15, bottom: 15 },
                                    theme: "striped",
                                    showHead: "firstPage"
                    });
                });
                //---------------------------------------------------------
                //---Total page number plugin only available in jspdf v1.0+
                //---------------------------------------------------------
                if (typeof doc.putTotalPages === 'function') {
                    doc.putTotalPages(totalPagesExp);
                }
                return doc
            },
            addBestEngine(doc, columns) {
                var finalY = doc.lastAutoTable.finalY + 10;
                doc.setTextColor(255, 26, 0);
                doc.text('Best Engine', 10, finalY);
                doc.setTextColor(255, 0, 0);
                var body = this.sortArrayByTwoProperties(this.scores, 'underHood', false, 'total', false).slice(0, 1);
                doc.autoTable({ startY: finalY+3, 
                                columns, 
                                body,
                                styles: { pageBreak: 'avoid', overflow: 'hidden' },
                                margin: { top: 25, left: 15, right: 15, bottom: 15 },
                                theme: "striped",
                                showHead: "firstPage"
                });
            },
            addBestWheels(doc, columns) {
                var finalY = doc.lastAutoTable.finalY + 10;
                doc.setTextColor(255, 26, 0);
                doc.text('Best Wheels', 10, finalY);
                doc.setTextColor(255, 0, 0);
                var body = this.sortArrayByTwoProperties(this.scores, 'wheels', false, 'total', false).slice(0, 1);
                doc.autoTable({ startY: finalY+3, 
                                columns, 
                                body,
                                styles: { pageBreak: 'avoid', overflow: 'hidden' },
                                margin: { top: 25, left: 15, right: 15, bottom: 15 },
                                theme: "striped",
                                showHead: "firstPage"
                });
            },
            addBestInterior(doc, columns) {
                var finalY = doc.lastAutoTable.finalY + 10;
                doc.setTextColor(255, 26, 0);
                doc.text('Best Interior', 10, finalY);
                doc.setTextColor(255, 0, 0);
                var body = this.sortArrayByTwoProperties(this.scores, 'interior', false, 'total', false).slice(0, 1);
                doc.autoTable({ startY: finalY+3, 
                                columns, 
                                body,
                                styles: { pageBreak: 'avoid', overflow: 'hidden' },
                                margin: { top: 25, left: 15, right: 15, bottom: 15 },
                                theme: "striped",
                                showHead: "firstPage"
                });
            },
            addBestPaint(doc, columns) {
                var finalY = doc.lastAutoTable.finalY + 10;
                doc.setTextColor(255, 26, 0);
                doc.text('Best Paint', 10, finalY);
                doc.setTextColor(255, 0, 0);
                var body = this.sortArrayByTwoProperties(this.scores, 'exteriorBody', false, 'total', false).slice(0, 1);
                doc.autoTable({ startY: finalY+3, 
                                columns, 
                                body,
                                styles: { pageBreak: 'avoid', overflow: 'hidden' },
                                margin: { top: 25, left: 15, right: 15, bottom: 15 },
                                theme: "striped",
                                showHead: "firstPage"
                });
            },
            addBestInShow(doc, columns) {
                var finalY = doc.lastAutoTable.finalY + 10;
                doc.setTextColor(255, 26, 0);
                doc.text('Best In Show', 10, finalY);
                doc.setTextColor(255, 0, 0);
                var body = this.scores.sort(function (a, b) {  return b.total - a.total; }).slice(0, 1);
                doc.autoTable({ startY: finalY+3, 
                                columns, 
                                body,
                                styles: { pageBreak: 'avoid', overflow: 'hidden' },
                                margin: { top: 25, left: 15, right: 15, bottom: 15 },
                                theme: "striped",
                                showHead: "firstPage"
                });
            },
            sortArrayByTwoProperties(array, prop1, asc1 = false, prop2, asc2 = false) {
                return array.sort((a, b) => (
                    (asc1 ? a[prop1] - b[prop1] : b[prop1] - a[prop1])
                    || (asc2 ? a[prop2] - b[prop2] : b[prop2] - a[prop2])
                ));
            }
        },
        mounted() {
            if(this.isJudge) {
                this.columns.push(
                {
                    label: '',
                    field: 'after',
                    tdClass: 'text-center',
                    sortable: false,
                    width: '65px'
                })
            }
        },
        watch: {
            groupTags(newGroup) {
                if (newGroup.length == 1){
                    this.groupOptions = {
                        enabled: true,
                        collapsable: true
                    }
                } else {
                    this.groupOptions = {
                        enabled: false
                    }
                }
            },
            isJudge(value) {
                if(value) {
                    this.columns.push(
                    {
                        label: '',
                        field: 'after',
                        tdClass: 'text-center',
                        sortable: false,
                        width: '65px'
                    })
                }
            }
        }
    }
</script>

<style>
    .pointer {
        cursor: pointer;
    }

    .table-box {
        padding: 15px;
    }

    #draggable {
        text-align: center;
        background-color: rgb(252, 237, 220);
    }

    .b-table {
        height: 600px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .control .help.counter {
        float: right;
        margin-left: 0.5em;
        display: none;
    }

    .vgt-table{ 
        overflow-y: auto; height: 100px; 
    }
    
    .vgt-table th { 
        position: sticky; top: 0;
        padding: 0 .15em 0 .15em; 

    }

    .down-drop {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .dropzone {
        width: 100%;
    }

    /*.vgt-fixed-header {
        position: absolute;
        z-index: 10;
        width: 99.4%;
        height: 100px;
        overflow-x: auto;
        padding: 0 .15em 0 .15em; 
        vertical-align: middle;
    }*/


</style>