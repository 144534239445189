<template>
    <div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-10 col-md-3 col-lg-3 add-edit">
            <form @submit.prevent="onUpdateForm">
                <h3>Verify an Entry</h3>
                <div class="d-grid gap-1">
                    <div class="form-group">
                        <label>Car Number</label>
                        <input type="number" @blur="checkExist()" v-model="score.carNumber" class="form-control form-control-sm" />
                    </div>
                    <div class="form-group year">
                        <label>Year</label>
                        <select v-model="score.year" id="year" class="form-select form-control form-control-sm">
                            <option>Select</option>
                            <option v-for="year in filteredYears" v-bind:key="year.Year">
                                {{year.Year}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Category</label>
                        <select v-model="score.category" id="category" class="form-select form-control form-control-sm">
                            <option>Select</option>
                            <option v-for="cats in filteredCats" v-bind:key="cats.CategoryName">
                                {{cats.CategoryName}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group make">
                        <label>Make</label>
                        <select v-model="score.make" id="make" @change="getModels($event)" class="form-select form-control form-control-sm">
                            <option>Select</option>
                            <option v-for="make in filteredMakes" v-bind:key="make.VehicleMake">
                                {{make.VehicleMake}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group model">
                        <label>Model</label>
                        <select v-model="score.model" id="model" class="form-select form-control form-control-sm">
                            <option>Select</option>
                            <option v-for="model in filteredModels" v-bind:key="model.Model">
                                {{model.Model}}
                            </option>
                        </select>
                    </div>
                    <button class="btn btn-primary">Update</button>
                    <button class="btn btn-secondary" @click.prevent="onCancel" >Exit</button>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import { db } from '@/services/firebase/index.js';
    import _ from 'lodash';
    export default {
    data() {
            return {
            docId: '',
            score: {
                carNumber: '',
                year: 'Select',
                category: 'Select',
                make: 'Select',
                model: 'Select',
                exteriorBody: '',
                interior: '',
                underhood: '',
                wheels: '',
                trunk: '',
                total: ''
            },
            categories: [],
            years: [],
            makes: [],
            models: [],
            submitted: false,
        }
    },
    computed: {
        filteredCats() {
            return _.orderBy(this.categories, 'CategoryName', 'asc');
        },
        filteredYears() {
            return _.orderBy(this.years, 'Year', 'asc');
        },
        filteredMakes() {
            return _.orderBy(this.makes, 'VehicleMake', 'asc');
        },
        filteredModels() {
            return _.orderBy(this.models, 'Model', 'asc');
        },
        scoresDbName() {
            return this.$store.getters.getScoresDatabaseName;
        },
        categoryDbName() {
            return this.$store.getters.getCategoryDatabaseName;
        }
    },
    methods: {
        checkExist(){
            let vm = this;
            vm.categories = [];
            vm.years = [];
            vm.makes = [];
            vm.models = [];
            db.collection('scores')
                .where('carNumber', '==', vm.score.carNumber)
                .limit(1)
                .get()
                .then((querySnapshot) => {
                    if (querySnapshot.empty) {
                        alert('Car Number ' + vm.score.carNumber + ' not found.');
                    } else {
                        querySnapshot.forEach((doc) => {
                            console.log(doc.id, " => ", doc.data());
                            vm.docId = doc.id
                            this.queryForEntry();
                        });
                    }
                })
                .catch((error) => {
                    alert(error);
                    alert('Car Number ' + vm.score.carNumber + ' not found.');
                });
        },
        queryForEntry() {
            var vm = this;
             if (vm.score.carNumber) {
                 console.log(vm.docId);
                let dbRef = db.collection(this.scoresDbName).doc(vm.docId);
                dbRef.get().then((doc) => {
                    vm.score = doc.data();
                    vm.getModels(vm.score.make);
                }).catch((error) => {
                    console.log(error);
                    alert('Car Number ' + vm.score.carNumber + 'not found.');
                });
                this.getCategories();
                this.getYears();
                this.getMakes();
             }
        },
        onUpdateForm(event) {
            event.preventDefault()
            const vm = this;
            vm.submitted = true;
            db.collection(this.scoresDbName).doc(vm.docId)
            .update(vm.score).then(() => {
                alert("Score successfully updated!");
            }).catch((error) => {
                console.log(error);
                alert("Error saving Score!");
            });
            vm.submitted = false;
        },
        onCancel() {
            this.show = false;
            this.$router.push({ name: 'Score' });
        },
        getCategories() {
            db.collection(this.categoryDbName).get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.categories.push(doc.data());
                });
            })
            .catch((error) => {
                console.log('Error getting categories: ', error);
            });
        },
        getYears() {
            db.collection("years").get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.years.push(doc.data());
                });
            })
            .catch((error) => {
                console.log("Error getting years: ", error);
            });
        },
        getMakes() {
            db.collection("vehicle_make").get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.makes.push(doc.data());
                });
            })
            .catch((error) => {
                console.log("Error getting makes: ", error);
            });
        },
        getModels(make) {
            this.models = [];
            db.collection('vehicle_models').where('VehicleMake', '==', make).get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.models = doc.data().Models;
                });
            })
            .catch((error) => {
                console.log("Error getting makes: ", error);
            });
        }
    }
}
</script>